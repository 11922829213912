<template>
    <div>
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" v-if="!isDisabled" @click="addProject"
                       >添加</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" v-if="!isDisabled" @click="remove"
                    >移除</el-button>
                </div>
            </div>
            <p slot="elList">
                <el-table ref="pcListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
                    highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName === 'Code'"><span type="text"
                                    style="text-decoration:underline;color:#1874CD;">{{
                                        scope.row[col.FieldName] }}</span></span>
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
        <DialogEx :options="projectOptions" title="项目选择" style="height:100%;" @onOk="onProjectOk">
            <Project ref="poject" :config="dialogConfig" @onSelectedRow="onProjectSelectedRow">
            </Project>
        </DialogEx>
    </div>
</template>

<script>
export default {
    mounted() { },
    data() {
        return {
            multipleSelection: [],
            queryParam: {
                PageIndex: 1,
                PageSize: 10,
                PageParams: {},
                params: {}
            },
            dataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            },
            dialogConfig: {
                isDetailDisplay: false,
                isSelectionDisplay: true,
            },
            config: {
                isDetailDisplay: false,
                isSelectionDisplay: true,
            },
            projectOptions: {
                visible: false,
                size: 'large'
            },
            projectSelecteData: [],
        }
    },
    props: {
        contractData: {},
        isDisabled: false
    },
    methods: {
        getProjectList: function (Id) {
            if (typeof Id != 'undefined' && Id != '' && Id != null) {
                this.queryParam.params.ContractId = Id;
            }
            this.onPageChange();
        },
        reloadPageList: function () {
            this.initialize();
        },
        initialize() {
            this.onPageChange(this.queryParam);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        onPageChange(param) {
            var _this = this;
            var param = this.Utils.cloneObj(this.queryParam);
            var contractId = param.params.ContractId || this.contractData.Id;
            param.PageParams = {
                ContractId: contractId
            };
            this.$ajax.query("omsapi/contractinfo/getprgagelist", "post", param, data => {
                _this.dataSource = data;
                this.contractData.PrList = [];
            });
        },
        onDataSourceChange(ds) {
            var _this = this;
            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;

            });
        },
        addProject() {
            this.projectSelecteData = [];
            this.projectOptions.visible = true;
        },
        onProjectSelectedRow(rows) {
            this.projectSelecteData = rows;
        },
        onProjectOk() {
            if (this.projectSelecteData.length <= 0)
                return;
            this.dataSource.Result = this.dataSource.Result || [];
            this.contractData.PrList = this.contractData.PrList || [];
            this.projectSelecteData.forEach(item => {
                if (this.dataSource.Result.filter(m => m.ProjectId == item.Id).length <= 0) {
                    var newRow = {
                        "Id": null,
                        "ProjectId": item.Id,
                        "Code": item.Code,
                        "Name": item.Name,
                    };
                    this.dataSource.Result.push(newRow);
                    this.contractData.PrList.push(newRow);
                }
            });
        },
        remove() {
            var _this = this;
            if (this.multipleSelection.length <= 0) {
                return this.Utils.messageBox("请选择移除项目数据.", "warning");
            }
            this.$confirm('是否移除选择数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var removeList = this.multipleSelection.filter(m => m.Id == null);
                var delList = this.multipleSelection.filter(m => m.Id != null);

                if (_this.dataSource.Id !== null && delList.length > 0) {
                    _this.$ajax.send("omsapi/contractinfo/removeprojects", "post", delList, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox("移除成功.", "success");
                            delList.forEach(item => {
                                this.dataSource.Result.remove(item);
                                if (this.contractData.PrList.length > 0)
                                    this.contractData.PrList.push(item);
                            });
                        } else {
                            _this.Utils.messageBox("移除失败，清重试.", "warning");
                        }
                    });
                } else {
                    removeList.forEach(item => {
                        this.dataSource.Result.remove(item);
                        if (this.contractData.PrList.length > 0)
                            this.contractData.PrList.remove(item);
                    });

                }
            });

        }
    },
    components: {
        "Project": resolve => {
            require(['@/components/business/selector/projectinfoselector.vue'], resolve)
        },
    }
}
</script>

<style></style>
